<template>
    <div class="shopHome">
        <!-- <div class="shopHomeTitle">
            <div class="centerWidth flex alignCenter marginAuto" style="text-align:left;height:100%;">商城</div>
        </div> -->
        <nav-view :titleName="'商城'"></nav-view>
        <div class="centerWidth marginAuto flex spacebetween" style="margin-top:30px;">
            <div class="shopType">
                <div class="shoptypeTitle flex alignCenter spacearound">商城分类</div>
                <div class="shoptypeList flex alignCenter spacearound cursorP" @click="goother({path:'/shoplist', query:{type:3, id: im.id, title: im.name}})" v-for="(im,ix) in shoptypeList" :key="ix">
                    <div class="c33 currtype">
                    {{im.name}}<i class="el-icon-arrow-right" style="padding-left:10px;"></i>
                    </div>
                </div>
            </div>
            <div class="shopList flex flexWrap spacebetween">
                <div class="courseTitle flex spacebetween alignCenter" style="height: 50px;">
                    <div class="courseTitleLeft c33 flex alignCenter" style="height: 35px;">推荐好物</div>
                    <div class="courseTitleRight c33 flex alignCenter" @click="goother({path:'/shoplist', query:{type:1, title: '推荐好物'}})">
                    更多<div style="margin-left:19px;" class="img"></div>
                    </div>
                </div>
                <shops style="margin-top: -10px;" :shopList="goodsRecommend"></shops>
                <div style="width:100%;" class="flex flexWrap spacebetween" v-for="(im,ix) in goodsPlate" :key="ix">
                    <div class="courseTitle flex spacebetween alignCenter" style="height: 50px;margin-top: 15px;">
                        <div class="courseTitleLeft c33 flex alignCenter">{{im.name}}</div>
                        <div class="courseTitleRight c33 flex alignCenter" @click="goother({path:'/shoplist', query:{type:2,id: im.id, title: im.name}})">
                        更多<div style="margin-left:19px;" class="img"></div>
                        </div>
                    </div>
                    <shops style="margin-top: -10px;" :shopList="im.goods"></shops>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get_goods_type, get_goods_recommend, get_goods_plate } from '@/utils/Api/goods'
import shops from '@/components/shops.vue'
import navView from '@/components/navView'
export default {
    name:'shopHome',
    components:{
        shops,navView
    },
    data(){
        return{
            shoptypeList:[],
            goodsRecommend:[],
            goodsPlate:[]
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(){
            let res = await get_goods_type({page:1, limit: 99999})
            this.shoptypeList = res
            let goodsRecommend = await get_goods_recommend({page:1, limit: 8})
            this.goodsRecommend = goodsRecommend
            let goodsPlate = await get_goods_plate()
            this.goodsPlate = goodsPlate
        }
    }
}
</script>
<style lang="scss" scoped>
.shopHome{
    background: #F7F8FA;
    .shopList{
        flex: 1;
        margin-bottom: 78px;
        .shopprice{
            height: 33px;
            width: 210px;
            .shopnum{
                font-size: 13px;
                color: #939393;
            }
            .shoppriceLeft{
                font-size: 24px;
                color: #F52121;
                span{
                    font-size: 12px;
                }
            }
            margin-top: 15px;
            align-items: flex-end;
        }
    }
    .shopInfo{
        width: 222px;
        height: 304px;
        opacity: 1;
        background: #ffffff;
        border-radius: 9px;
        margin-top: 20px;
        cursor: pointer;
        transition: all .2s;
        &:hover{margin-top: 14px;}
        .shopname{
            margin-top: 12px;
            font-size: 16px;
            width: 210px;
            text-align: left;
        }
        img{
            width: 210px;
            height: 210px;
            margin-top: 7px;
        }
    }
    .shopHomeTitle{
        width: 100%;
        height: 67px;
        background: #47D7E3;
        color: #fff;
        font-size: 24px;
    }
    .shopType{
        width: 228px;
        position: sticky;
        top: 30px;
        height: 500px;
        background: #fff;
        overflow-y: auto;
        margin-right: 40px;
        .shoptypeTitle{
            width: 228px;
            height: 55px;
            background: #50D7E2;
            color: #fff;
        }
        .shoptypeList{
            height: 73px;
            width: 100%;
            background: #fff;
            border-bottom: 1px solid #F0F0F0;
            transition: all .1s;
            &:hover{
                background: #50D7E2;
                .currtype{
                    color: #fff!important;
                }
            }
        }
    }
}
</style>